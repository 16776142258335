@font-face {
  font-family: "Akrobat";
  src: local("Akrobat"),
    url(./assets/fonts/Akrobat-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
    url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "QuickSand";
  src: local("QuickSand"),
    url(./assets/fonts/Quicksand-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Akrobat";
}

/* Set the style of the scrollbar */
::-webkit-scrollbar {
  height: 10px;
  width: 10px; /* Set the width of the scrollbar */
}

/* Set the style of the thumb */
::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Set the color of the thumb */
  transition: background-color 500ms ease;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
::-webkit-scrollbar-thumb:active {
  background-color: #777;
}

/* Set the style of the track */
::-webkit-scrollbar-track {
  background-color: #0000; /* Set the color of the track */
}

::-webkit-scrollbar-track:hover {
  background-color: #f8f7f7; /* Set the color of the track */
  border-left: 1px solid #ccc;
}

.rccs {
  margin: 0 !important;
}

a {
  text-decoration: none;
  color: inherit;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  background-color: transparent !important;
}

::selection {
  background-color: #0003;
}

.StripeElement {
  width: 100%;
}

.MuiInputBase-root {
  border-radius: 0 !important;
}